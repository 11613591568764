import { graphql, readInlineData } from "react-relay";

import { section as dataSection } from "scmp-app/data/section";
import type { helpersCheckIfNewsSectionInFirstIndexSection$key } from "scmp-app/queries/__generated__/helpersCheckIfNewsSectionInFirstIndexSection.graphql";

export const checkIfNewsSectionInFirstIndex = (
  section_: Nullish<helpersCheckIfNewsSectionInFirstIndexSection$key>,
  index: number,
) => {
  if (!section_) return false;

  const section = readInlineData(
    graphql`
      fragment helpersCheckIfNewsSectionInFirstIndexSection on Section @inline {
        name
      }
    `,
    section_,
  );
  return index === 0 && section?.name === dataSection.news.name;
};
