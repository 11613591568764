/**
 * @generated SignedSource<<95efb220e6925fb8b19d9b51a2725acc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSubHeadlineContent$data = {
  readonly subHeadline: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly summary: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
  readonly " $fragmentType": "contentSubHeadlineContent";
};
export type contentSubHeadlineContent$key = {
  readonly " $data"?: contentSubHeadlineContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSubHeadlineContent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "json",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSubHeadlineContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "subHeadline",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "92c6e7c79493e90a2a34d489fd95ef43";

export default node;
