/**
 * @generated SignedSource<<8cb8a6fe83f0ebfc84be9979df9b24bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIfNewsSectionInFirstIndexSection$data = {
  readonly name: string;
  readonly " $fragmentType": "helpersCheckIfNewsSectionInFirstIndexSection";
};
export type helpersCheckIfNewsSectionInFirstIndexSection$key = {
  readonly " $data"?: helpersCheckIfNewsSectionInFirstIndexSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIfNewsSectionInFirstIndexSection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIfNewsSectionInFirstIndexSection"
};

(node as any).hash = "6e8f2841292d960663692ee075e0d780";

export default node;
