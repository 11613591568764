import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";

export type StyledProps = {
  $color: string;
  $gap: string;
  $imageVariant?: "large" | "small";
  $layoutAlignment: "center" | "start";
  $layoutDirection: "horizontal" | "vertical";
  $nameColor: string;
  $nameFontSize: string;
  $nameLineHeight: string;
  $typeFontSize: string;
  $typeLineHeight: string;
};

export const SponsorType = styled.span`
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 14px;
`;

export const SponsorName = styled.span`
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
`;

export const SponsorImage = styled(BaseImage)``;

export const SponsorTagContainer = styled.div<StyledProps>`
  display: flex;
  flex-flow: ${props => (props.$layoutDirection === "horizontal" ? "row" : "column")};
  gap: ${props => props.$gap};
  justify-content: flex-start;
  align-items: ${props => (props.$layoutAlignment === "start" ? "flex-start" : "center")};

  color: ${props => props.$color};

  ${SponsorType} {
    font-size: ${props => props.$typeFontSize};
    line-height: ${props => props.$typeLineHeight};
  }

  ${SponsorName} {
    color: ${props => props.$nameColor};
    font-size: ${props => props.$nameFontSize};
    line-height: ${props => props.$nameLineHeight};
  }

  ${SponsorImage} {
    ${props => {
      switch (props.$imageVariant) {
        case "large":
          return css`
            block-size: auto;
            max-inline-size: 80px;
          `;
        case "small":
          return css`
            inline-size: auto;
            max-block-size: 42px;
          `;
      }
    }}
  }
`;

export const HomeSponsorTagContainer = styled.div`
  display: block;
`;

export const HomeSponsorTagWrapper = styled.div`
  display: inline-block;

  padding-block: 4px;
  padding-inline: 6px;

  border: 1px solid #00000033;
`;

export const HomeSponsorType = styled.span`
  display: block;

  margin-block-end: 4px;

  color: #00000080;
  font-weight: 400;
  font-size: 12px;
`;

export const HomeSponsorName = styled.div`
  color: #1e1e1e;
  font-weight: 700;
  font-size: 12px;
`;
