/**
 * @generated SignedSource<<b153966c934ccfa5e4b55a6d2768e02b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSponsorTagContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersUseContentSponsorContent">;
  readonly " $fragmentType": "contentSponsorTagContent";
};
export type contentSponsorTagContent$key = {
  readonly " $data"?: contentSponsorTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSponsorTagContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSponsorTagContent",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersUseContentSponsorContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Sponsor",
              "kind": "LinkedField",
              "name": "sponsor",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sponsorTagSponsor"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sponsorType",
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMainTopicContent",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topics",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "730610b679ed7a1f2f97ed01d4a1d688";

export default node;
