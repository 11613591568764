import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { SponsorTag } from "scmp-app/components/sponsor-tag";
import type { contentSponsorTagContent$key } from "scmp-app/queries/__generated__/contentSponsorTagContent.graphql";

import { getContentSponsor } from "./helpers";

export type Props = {
  className?: string;
  reference: contentSponsorTagContent$key;
};

export const ContentSponsorTag: FunctionComponent<Props> = ({
  reference: reference_,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentSponsorTagContent on Content {
        ...helpersUseContentSponsorContent
      }
    `,
    reference_,
  );
  const contentSponsor = getContentSponsor(content);
  if (contentSponsor?.name) {
    return <SponsorTag reference={contentSponsor} {...props} />;
  }
  return null;
};

ContentSponsorTag.displayName = "ContentSponsorTag";
